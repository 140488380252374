import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Container, MenuItem, TextField, Typography } from '@mui/material';

// Alerts
import { setError, setSuccess } from '../../../../../alerts';

// API
import { getCamera, getLatestSatelliteMessageForCamera, updateCameraConfiguration } from '../../../../../api/admin';

// Components
import { ConfirmationModal, LineItem, BackToButton } from '../../../../../components';

// Constants
import { LIGHT_GREY } from '../../../../../constants';

class EditConfigModal extends Component {
  constructor(props) {
    super(props);

    let inputs = [];
    for (let i = 0; i < 4; i++) inputs.push({
      enableInput: '',
      enableTrigger: '',
      triggerStart: '',
      triggerStop: '',
      type: null
    });

    this.state = {
      camera: null,
      confirmUpdate: false,
      redirectToCamera: false,
      isLoading: false,

      configurationFiles: [],

      reportingInterval: '',
      mailboxInterval: '',
      reportFormat: '',
      triggerHold: '',
      recordingExtension: '',
      powerMode: '',
      configurationFileID: '',
      nodemap: '',
      lowlightNodemap: '',

      enableAutoTimer: '',
      enableAutoTimerTrigger: '',
      autoTimerTriggerStart: '',
      autoTimerTriggerStop: '',

      enableGpsd: '',
      enableGpsdTrigger: '',
      gpsdTriggerCenterPointLng: '',
      gpsdTriggerCenterPointLat: '',
      gpsdTriggerRadius: '',

      enableManualRecording: '',
      enableManualRecordingTrigger: '',

      enableInternalTemperature: '',
      enableInternalTemperatureTrigger: '',
      internalTemperatureTriggerStart: '',
      internalTemperatureTriggerStop: '',

      enableChargerStatus: '',
      enableChargerStatusTrigger: '',

      inputs: inputs
    };
  }

  componentDidMount() {
    const cameraID = this.props.match?.params?.id;

    // Retrieve configuration file list, reporting interval, and mailbox interval
    getCamera(cameraID).then(camera => {
      this.setState({
        camera,
        configurationFiles: camera.getConfigurationFiles(),
        reportingInterval: camera.getReportingInterval() ? camera.getReportingInterval() : '',
        mailboxInterval: camera.getMailboxInterval() ? camera.getMailboxInterval() : ''
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve list of configuration files.');
    });

    // Update the inputs with type
    getLatestSatelliteMessageForCamera(cameraID).then(satelliteMessage => {
      satelliteMessage?.getCameraInputs()?.forEach(cameraInput => {
        if (cameraInput.getInputNumber() != null && cameraInput.getInputNumber() <= this.state.inputs.length) {
          this.setState({ inputs: this.state.inputs.map((input, mapIndex) => cameraInput.getInputNumber() === mapIndex + 1 ? { ...input, type: cameraInput.getType() } : input ) });
        }
      });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to retrieve input device types.');
    });
  }

  confirmUpdateConfig = (e) => {
    e.preventDefault();

    // Compile list of changes
    let listOfChanges = [];

    const {
      camera,
      reportingInterval, mailboxInterval,
      reportFormat, triggerHold, recordingExtension, powerMode, configurationFileID, nodemap, lowlightNodemap,
      enableAutoTimer, enableAutoTimerTrigger, autoTimerTriggerStart, autoTimerTriggerStop,
      enableGpsd, enableGpsdTrigger, gpsdTriggerCenterPointLng, gpsdTriggerCenterPointLat, gpsdTriggerRadius,
      enableManualRecording, enableManualRecordingTrigger,
      enableInternalTemperature, enableInternalTemperatureTrigger, internalTemperatureTriggerStart, internalTemperatureTriggerStop,
      enableChargerStatus, enableChargerStatusTrigger,
      inputs
    } = this.state;
    if (reportingInterval !== camera.getReportingInterval()) listOfChanges.push('Set Reporting Interval to ' + reportingInterval);
    if (mailboxInterval !== camera.getMailboxInterval()) listOfChanges.push('Set Mailbox Interval to ' + mailboxInterval);

    if (reportFormat !== '') listOfChanges.push('Set Report Format to ' + (reportFormat));
    if (triggerHold !== '') listOfChanges.push('Set Trigger Hold to ' + (triggerHold ? 'Enabled' : 'Disabled'));
    if (recordingExtension !== '') listOfChanges.push('Set Recording Extension to ' + recordingExtension);
    if (powerMode !== '') listOfChanges.push('Set Power Mode to ' + powerMode);
    if (configurationFileID !== '') listOfChanges.push('Set Configuration File to ' + this.state.configurationFiles.find(file => file.getID() === configurationFileID)?.getFileName());
    if (nodemap !== '') listOfChanges.push('Set Node Map to ' + nodemap);
    if (lowlightNodemap !== '') listOfChanges.push('Set Low Light Node Map to ' + lowlightNodemap);

    if (enableAutoTimer !== '') listOfChanges.push('Set Auto Timer to ' + (enableAutoTimer ? 'Enabled' : 'Disabled'));
    if (enableAutoTimerTrigger !== '') listOfChanges.push('Set Auto Timer Trigger to ' + (enableAutoTimerTrigger ? 'Enabled' : 'Disabled'));
    if (autoTimerTriggerStart !== '') listOfChanges.push('Set Auto Timer Trigger Start to ' + autoTimerTriggerStart);
    if (autoTimerTriggerStop !== '') listOfChanges.push('Set Auto Timer Trigger Stop to ' + autoTimerTriggerStop);

    if (enableGpsd !== '') listOfChanges.push('Set GPSD to ' + (enableGpsd ? 'Enabled' : 'Disabled'));
    if (enableGpsdTrigger !== '') listOfChanges.push('Set GPSD Trigger to ' + (enableGpsdTrigger ? 'Enabled' : 'Disabled'));
    if (gpsdTriggerCenterPointLng !== '') listOfChanges.push('Set GPSD Trigger Center Point Lng to ' + gpsdTriggerCenterPointLng);
    if (gpsdTriggerCenterPointLat !== '') listOfChanges.push('Set GPSD Trigger Center Point Lat to ' + gpsdTriggerCenterPointLat);
    if (gpsdTriggerRadius !== '') listOfChanges.push('Set GPSD Trigger Radius to ' + gpsdTriggerRadius);

    if (enableManualRecording !== '') listOfChanges.push('Set Manual Recording to ' + (enableManualRecording ? 'Enabled' : 'Disabled'));
    if (enableManualRecordingTrigger !== '') listOfChanges.push('Set Manual Recording Trigger to ' + (enableManualRecordingTrigger ? 'Enabled' : 'Disabled'));

    if (enableInternalTemperature !== '') listOfChanges.push('Set Internal Temperature to ' + (enableInternalTemperature ? 'Enabled' : 'Disabled'));
    if (enableInternalTemperatureTrigger !== '') listOfChanges.push('Set Internal Temperature Trigger to ' + (enableInternalTemperatureTrigger ? 'Enabled' : 'Disabled'));
    if (internalTemperatureTriggerStart !== '') listOfChanges.push('Set Internal Temperature Trigger Start to ' + internalTemperatureTriggerStart);
    if (internalTemperatureTriggerStop !== '') listOfChanges.push('Set Internal Temperature Trigger Stop to ' + internalTemperatureTriggerStop);

    if (enableChargerStatus !== '') listOfChanges.push('Set Charger Status to ' + (enableChargerStatus ? 'Enabled' : 'Disabled'));
    if (enableChargerStatusTrigger !== '') listOfChanges.push('Set Charger Status Trigger to ' + (enableChargerStatusTrigger ? 'Enabled' : 'Disabled'));

    inputs.forEach((input, index) => {
      let inputNum = index + 1;
      if (input.enableInput !== '') listOfChanges.push('Set Input ' + inputNum + ' to ' + (input.enableInput ? 'Enabled' : 'Disabled'));
      if (input.enableTrigger !== '') listOfChanges.push('Set Input ' + inputNum + ' Trigger to ' + (input.enableTrigger ? 'Enabled' : 'Disabled'));
      if (input.triggerStart !== '') listOfChanges.push('Set Input ' + inputNum + ' Trigger Start to ' + input.triggerStart);
      if (input.triggerStop !== '') listOfChanges.push('Set Input ' + inputNum + ' Trigger Stop to ' + input.triggerStop);
    });

    if (listOfChanges.length === 0) {
      setError('No changes were made.');
      return;
    }

    this.setState({ listOfChanges, confirmUpdate: true });
  }

  updateConfig = () => {

    const {
      camera,
      reportingInterval, mailboxInterval,
      reportFormat, triggerHold, recordingExtension, powerMode, configurationFileID, nodemap, lowlightNodemap,
      enableAutoTimer, enableAutoTimerTrigger, autoTimerTriggerStart, autoTimerTriggerStop,
      enableGpsd, enableGpsdTrigger, gpsdTriggerCenterPointLng, gpsdTriggerCenterPointLat, gpsdTriggerRadius,
      enableManualRecording, enableManualRecordingTrigger,
      enableInternalTemperature, enableInternalTemperatureTrigger, internalTemperatureTriggerStart, internalTemperatureTriggerStop,
      enableChargerStatus, enableChargerStatusTrigger,
      inputs
    } = this.state;

    let updatedConfiguration = {};

    if (reportingInterval !== camera.getReportingInterval()) updatedConfiguration.reportingInterval = parseFloat(reportingInterval);
    if (mailboxInterval !== camera.getMailboxInterval()) updatedConfiguration.mailboxInterval = parseFloat(mailboxInterval);

    if (reportFormat !== '') updatedConfiguration.reportFormat = reportFormat;
    if (triggerHold !== '') updatedConfiguration.triggerHold = triggerHold;
    if (recordingExtension !== '') updatedConfiguration.recordingExtension = parseFloat(recordingExtension);
    if (powerMode !== '') updatedConfiguration.powerMode = powerMode;
    if (configurationFileID !== '') updatedConfiguration.configurationFileId = configurationFileID;
    if (nodemap !== '') updatedConfiguration.nodemap = nodemap;
    if (lowlightNodemap !== '') updatedConfiguration.lowlightNodemap = lowlightNodemap;

    if (enableAutoTimer !== '') updatedConfiguration.enableAutoTimer = enableAutoTimer;
    if (enableAutoTimerTrigger !== '') updatedConfiguration.enableAutoTimerTrigger = enableAutoTimerTrigger;
    if (autoTimerTriggerStart !== '') updatedConfiguration.autoTimerTriggerStart = autoTimerTriggerStart;
    if (autoTimerTriggerStop !== '') updatedConfiguration.autoTimerTriggerStop = autoTimerTriggerStop;

    if (enableGpsd !== '') updatedConfiguration.enableGpsd = enableGpsd;
    if (enableGpsdTrigger !== '') updatedConfiguration.enableGpsdTrigger = enableGpsdTrigger;
    if (gpsdTriggerCenterPointLng !== '') updatedConfiguration.gpsdTriggerCenterPointLng = parseFloat(gpsdTriggerCenterPointLng);
    if (gpsdTriggerCenterPointLat !== '') updatedConfiguration.gpsdTriggerCenterPointLat = parseFloat(gpsdTriggerCenterPointLat);
    if (gpsdTriggerRadius !== '') updatedConfiguration.gpsdTriggerRadius = parseFloat(gpsdTriggerRadius);

    if (enableManualRecording !== '') updatedConfiguration.enableManualRecording = enableManualRecording;
    if (enableManualRecordingTrigger !== '') updatedConfiguration.enableManualRecordingTrigger = enableManualRecordingTrigger;

    if (enableInternalTemperature !== '') updatedConfiguration.enableInternalTemperature = enableInternalTemperature;
    if (enableInternalTemperatureTrigger !== '') updatedConfiguration.enableInternalTemperatureTrigger = enableInternalTemperatureTrigger;
    if (internalTemperatureTriggerStart !== '') updatedConfiguration.internalTemperatureTriggerStart = parseFloat(internalTemperatureTriggerStart);
    if (internalTemperatureTriggerStop !== '') updatedConfiguration.internalTemperatureTriggerStop = parseFloat(internalTemperatureTriggerStop);

    if (enableChargerStatus !== '') updatedConfiguration.enableChargerStatus = enableChargerStatus;
    if (enableChargerStatusTrigger !== '') updatedConfiguration.enableChargerStatusTrigger = enableChargerStatusTrigger;

    let inputObjectList = [];
    inputs.forEach((input, index) => {
      let inputObject = {};
      if (input.enableInput !== '') inputObject.enableInput = input.enableInput;
      if (input.enableTrigger !== '') inputObject.enableTrigger = input.enableTrigger;
      if (input.triggerStart !== '') inputObject.triggerStart = parseFloat(input.triggerStart);
      if (input.triggerStop !== '') inputObject.triggerStop = parseFloat(input.triggerStop);

      if (Object.keys(inputObject)?.length > 0) {
        inputObject.inputNumber = index + 1;
        inputObjectList.push(inputObject);
      }
    });
    if (inputObjectList.length > 0) updatedConfiguration.inputs = inputObjectList;

    const cameraID = this.props.match?.params?.id;

    // Update configuration with server
    updateCameraConfiguration(cameraID, updatedConfiguration).then(() => {
      setSuccess('Successfully updated camera configuration.');
      this.setState({ isLoading: false, redirectToCamera: true });
    }).catch(error => {
      setError(error ? error : 'Error: Unable to update camera configuration.');
      this.setState({ isLoading: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onInputChange = (e, inputIndex) => {
    this.setState({ inputs: this.state.inputs.map((input, mapIndex) => inputIndex === mapIndex ? { ...input, [e.target.name]: e.target.value } : input ) });
  }

  render() {
    return this.state.redirectToCamera ? <Redirect to={'/admin/cameras/' + this.props.match?.params?.id} /> : (
      <>
        <ConfirmationModal
          title='Confirm Changes'
          body={
            <>
              <Typography variant='body1'>The following changes will be made:</Typography>
              {this.state.listOfChanges?.map((item, index) => (
                <Typography key={index} variant='body2'>{item}</Typography>
              ))}
            </>
          }
          open={this.state.confirmUpdate}
          onClose={() => this.setState({ confirmUpdate: false })}
          onConfirm={this.updateConfig}
          isLoading={this.state.isLoading}
        />

        <Container style={{ marginBottom: 50, marginTop: 30 }}>

          <BackToButton to={'/admin/cameras/' + this.state.camera?.getID()} description={'Camera'} />
          <Typography variant='h1' style={{ margin: '1em', textAlign: 'center' }}>Edit Configuration</Typography>

          <form onSubmit={this.confirmUpdateConfig}>
            {/* ---- Basic Configuration ----- */}
            <>
              {/* Reporting Interval */}
              <TextField
                required
                name='reportingInterval'
                label='Reporting Interval'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.reportingInterval}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                type='number'
                onWheel={(e) => e.target.blur()}
                helperText='Enter a decimal number representing the number of hours for the reporting interval. Example: 0.05 would be 3 minutes.'
                inputProps={{
                  step: '0.01',
                  min: '0.02'
                }}
              />

              {/* Mailbox Interval */}
              <TextField
                required
                name='mailboxInterval'
                label='Mailbox Interval'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.mailboxInterval}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                type='number'
                onWheel={(e) => e.target.blur()}
                helperText='Enter a decimal number representing the number of hours for the mailbox interval. Example: 0.05 would be 3 minutes.'
                inputProps={{
                  step: '0.01',
                  min: '0.02'
                }}
              />

              {/* Report Format */}
              <TextField
                name='reportFormat'
                label='Report Format'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.reportFormat}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={'last-sensor'}>Last Sensor</MenuItem>
                <MenuItem value={'avg-sensor'}>Average Sensor</MenuItem>
              </TextField>

              {/* Trigger Hold */}
              <TextField
                name='triggerHold'
                label='Enable/Disable Trigger Hold'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.triggerHold}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              {/* Recording Extension */}
              <TextField
                name='recordingExtension'
                label='Recording Extension'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.recordingExtension}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                type='number'
                onWheel={(e) => e.target.blur()}
                helperText='Enter a integer number representing the number of minutes for the recording extension.'
                inputProps={{
                  step: '1',
                  min: 1
                }}
              />

              {/* Power Mode */}
              <TextField
                name='powerMode'
                label='Power Mode'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.powerMode}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value='high'>high</MenuItem>
                <MenuItem value='low'>low</MenuItem>
              </TextField>

              {/* Configuration File */}
              <TextField
                name='configurationFileID'
                label='Configuration File'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.configurationFileID}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                {this.state.configurationFiles.map((configurationFile, index) => (
                  <MenuItem key={index} value={configurationFile.getID()}>{configurationFile.getFileName()}</MenuItem>
                ))}
              </TextField>

              {/* Node Map */}
              <TextField
                name='nodemap'
                label='Node Map'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.nodemap}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
              />

              {/* Low Light Node Map */}
              <TextField
                name='lowlightNodemap'
                label='Low Light Node Map'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.lowlightNodemap}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
              />
            </>

            {/* ----- Auto Timer ----- */}
            <>
              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Auto Timer</Typography>

              {/* Auto Timer Enabled */}
              <TextField
                name='enableAutoTimer'
                label='Enable/Disable Auto Timer'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableAutoTimer}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              <Typography variant='h5' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trigger</Typography>

              {/* Trigger Enable/Disable */}
              <TextField
                name='enableAutoTimerTrigger'
                label='Enable/Disable Auto Timer Trigger'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableAutoTimerTrigger}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              {/* Trigger Start */}
              <TextField
                name='autoTimerTriggerStart'
                label='Auto Timer Trigger Start'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.autoTimerTriggerStart}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                type='text'
                onWheel={(e) => e.target.blur()}
                helperText='Enter a timestamp in 24 hour format for the Auto Timer Trigger.'
              />

              {/* Trigger Stop */}
              <TextField
                name='autoTimerTriggerStop'
                label='Auto Timer Trigger Stop'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.autoTimerTriggerStop}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                type='text'
                onWheel={(e) => e.target.blur()}
                helperText='Enter a timestamp in 24 hour format for the Auto Timer Trigger.'
              />
            </>

            {/* ----- GPSD ----- */}
            <>
              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>GPSD</Typography>

              {/* GPSD Enabled */}
              <TextField
                name='enableGpsd'
                label='Enable/Disable GPSD'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableGpsd}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              <Typography variant='h5' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trigger</Typography>

              {/* Trigger Enable/Disable */}
              <TextField
                name='enableGpsdTrigger'
                label='Enable/Disable GPSD Trigger'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableGpsdTrigger}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              {/* Trigger Center Point */}
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <TextField
                  name='gpsdTriggerCenterPointLat'
                  label='GPSD Trigger Center Point Lat'
                  style={{ width: '49%', marginBottom: '10px' }}
                  value={this.state.gpsdTriggerCenterPointLat}
                  onChange={this.onChange}
                  variant='filled'
                  disabled={this.state.isLoading}
                  type='number'
                  onWheel={(e) => e.target.blur()}
                />

                {/* Trigger Stop */}
                <TextField
                  name='gpsdTriggerCenterPointLng'
                  label='GPSD Trigger Center Point Lng'
                  style={{ width: '49%', marginBottom: '10px' }}
                  value={this.state.gpsdTriggerCenterPointLng}
                  onChange={this.onChange}
                  variant='filled'
                  disabled={this.state.isLoading}
                  type='number'
                  onWheel={(e) => e.target.blur()}
                />
              </div>

              {/* Trigger Radius */}
              <TextField
                name='gpsdTriggerRadius'
                label='GPSD Trigger Radius'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.gpsdTriggerRadius}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                type='number'
                onWheel={(e) => e.target.blur()}
                helperText='Enter a decimal number representing the radius in miles of the GPSD trigger.'
                inputProps={{
                  step: '0.01',
                  min: '0.01'
                }}
              />
            </>

            {/* ----- Manual Recording ----- */}
            <>
              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Manual Recording</Typography>

              {/* Manual Recording Enabled */}
              <TextField
                name='enableManualRecording'
                label='Enable/Disable Manual Recording'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableManualRecording}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              <Typography variant='h5' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trigger</Typography>

              {/* Trigger Enable/Disable */}
              <TextField
                name='enableManualRecordingTrigger'
                label='Enable/Disable Manual Recording Trigger'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableManualRecordingTrigger}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>
            </>

            {/* ----- Internal Temperature ----- */}
            <>
              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Internal Temperature</Typography>

              {/* Internal Temperature Enabled */}
              <TextField
                name='enableInternalTemperature'
                label='Enable/Disable Internal Temperature'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableInternalTemperature}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              <Typography variant='h5' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trigger</Typography>

              {/* Trigger Enable/Disable */}
              <TextField
                name='enableInternalTemperatureTrigger'
                label='Enable/Disable Internal Temperature Trigger'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableInternalTemperatureTrigger}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              {/* Trigger Start */}
              <TextField
                name='internalTemperatureTriggerStart'
                label='Internal Temperature Trigger Start'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.internalTemperatureTriggerStart}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                type='number'
                onWheel={(e) => e.target.blur()}
                helperText='Enter a integer number representing the degrees celcius of the Internal Temperature Trigger.'
                inputProps={{
                  step: '1',
                  min: '-100'
                }}
              />

              {/* Trigger Stop */}
              <TextField
                name='internalTemperatureTriggerStop'
                label='Internal Temperature Trigger Stop'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.internalTemperatureTriggerStop}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                type='number'
                onWheel={(e) => e.target.blur()}
                helperText='Enter a integer number representing the degrees celcius of the Internal Temperature Trigger.'
                inputProps={{
                  step: '1',
                  min: '-100'
                }}
              />
            </>

            {/* ----- Charger Status ----- */}
            <>
              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Charger Status</Typography>

              {/* Charger Status Enabled */}
              <TextField
                name='enableChargerStatus'
                label='Enable/Disable Charger Status'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableChargerStatus}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>

              <Typography variant='h5' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trigger</Typography>

              {/* Trigger Enable/Disable */}
              <TextField
                name='enableChargerStatusTrigger'
                label='Enable/Disable Charger Status Trigger'
                style={{ width: '100%', marginBottom: '10px' }}
                value={this.state.enableChargerStatusTrigger}
                onChange={this.onChange}
                variant='filled'
                disabled={this.state.isLoading}
                select
              >
                <MenuItem value=''>-</MenuItem>
                <MenuItem value={true}>Enable</MenuItem>
                <MenuItem value={false}>Disable</MenuItem>
              </TextField>
            </>

            {/* ----- Device Inputs ----- */}
            <>
              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Device Inputs</Typography>

              {this.state.inputs.map((input, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 20, boxSizing: 'border-box', backgroundColor: LIGHT_GREY, borderRadius: 10, marginBottom: 10 }}>
                  <Typography variant='h4' style={{ textAlign: 'center', marginBottom: '1em' }}>Input {index + 1}</Typography>

                  {/* Input Type */}
                  <LineItem
                    description='Input Type'
                    value={input.type ? input.type : 'Unavailable'}
                  />

                  {/* Input Enabled */}
                  <TextField
                    name='enableInput'
                    label='Enable/Disable Input'
                    style={{ width: '100%', marginBottom: '10px' }}
                    value={input.enableInput}
                    onChange={(e) => this.onInputChange(e, index)}
                    variant='filled'
                    disabled={this.state.isLoading}
                    select
                  >
                    <MenuItem value=''>-</MenuItem>
                    <MenuItem value={true}>Enable</MenuItem>
                    <MenuItem value={false}>Disable</MenuItem>
                  </TextField>

                  <Typography variant='h5' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Trigger</Typography>

                  {/* Trigger Enable/Disable */}
                  <TextField
                    name='enableTrigger'
                    label='Enable/Disable Trigger'
                    style={{ width: '100%', marginBottom: '10px' }}
                    value={input.enableTrigger}
                    onChange={(e) => this.onInputChange(e, index)}
                    variant='filled'
                    disabled={this.state.isLoading}
                    select
                  >
                    <MenuItem value=''>-</MenuItem>
                    <MenuItem value={true}>Enable</MenuItem>
                    <MenuItem value={false}>Disable</MenuItem>
                  </TextField>

                  {/* Trigger Start */}
                  <TextField
                    name='triggerStart'
                    label='Trigger Start'
                    style={{ width: '100%', marginBottom: '10px' }}
                    value={input.triggerStart}
                    onChange={(e) => this.onInputChange(e, index)}
                    variant='filled'
                    disabled={this.state.isLoading}
                    type='number'
                    onWheel={(e) => e.target.blur()}
                  />

                  {/* Trigger Stop */}
                  <TextField
                    name='triggerStop'
                    label='Trigger Stop'
                    style={{ width: '100%', marginBottom: '10px' }}
                    value={input.triggerStop}
                    onChange={(e) => this.onInputChange(e, index)}
                    variant='filled'
                    disabled={this.state.isLoading}
                    type='number'
                    onWheel={(e) => e.target.blur()}
                  />

                </div>
              ))}
            </>

            <Button type='submit' style={{ marginTop: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }} disabled={this.state.isLoading}>
              Send Configuration Update
              {this.state.isLoading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
            </Button>
          </form>
        </Container>
      </>
    );
  }
}

EditConfigModal.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(EditConfigModal);
