import PropTypes from 'prop-types';
import React from 'react';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Table } from '.';
import { FishCatch } from '../lib';
import { LBS_TO_KG, DATE_TIME_ZONE_FORMAT } from '../constants';
import { getFishCatch } from '../api';

const FISH_COLUMNS = ({ convertToKg, onViewItems }) => ([
  {
    title: 'Species',
    field: 'speciesName'
  },
  {
    title: `Weight ${convertToKg ? '(kg)' : '(lbs)'}`,
    render: rowData => (
      convertToKg ? (rowData.getWeight() * LBS_TO_KG).toFixed(1) : rowData.getWeight().toFixed(1)
    ),
    customSort: (a, b) => {
      const aWeight = a.getWeight();
      const bWeight = b.getWeight();
      
      return aWeight - bWeight;
    }
  },
  {
    title: 'N',
    render: rowData => (
      <Button disabled={rowData.speciesName === 'Species Not Specified'} style={{ opacity: 1 }} variant='text' onClick={() => onViewItems(rowData.getID())}>{rowData.getCount()}</Button>
    )
  }
]);

class FishCatchDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fishCatch: null,
      isLoading: false
    };
  }

  onClose = () => {
    this.setState({ fishCatch: null });
    this.props.onClose();
  }

  onViewItems = (fishCatchID) => {
    this.setState({ isLoading: true });
    getFishCatch(fishCatchID).then((fishCatch) => {
      this.setState({ fishCatch });
    }).catch(error => {
      console.log(error);
    }).finally(() => this.setState({ isLoading: false }));
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.onClose}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogContent dividers={scroll === 'paper'}>
            {this.state.isLoading ? <div>Loading...</div> : (
              this.state.fishCatch ? (
                <div>
                  <Button onClick={() => this.setState({ fishCatch: null })}>Back</Button>
                  <Table
                    title={this.state.fishCatch?.getSpeciesName()}
                    data={this.state.fishCatch.getItems()}
                    columns={[
                      {
                        title: 'Timestamp',
                        field: 'timestamp',
                        render: rowData => rowData.timestamp != null ? (
                          rowData.getAwsImagePath() != null ? (
                            <Button variant='text' disabled={this.props.onGoToImage == null} onClick={() => {
                              const splitPath = rowData.getAwsImagePath().split('/');
                              const path = splitPath[splitPath.length - 1];
                              this.props.onGoToImage(path);
                            }}>
                              {rowData.timestamp?.setZone(this.props.timezone)?.toLocaleString(DATE_TIME_ZONE_FORMAT)}
                            </Button>
                          ) : (
                            rowData.timestamp?.setZone(this.props.timezone)?.toLocaleString(DATE_TIME_ZONE_FORMAT)
                          )
                        ) : null
                      },
                      {
                        title: `Weight ${this.props.convertToKg ? '(kg)' : '(lbs)'}`,
                        field: 'weight',
                        render: rowData => (
                          this.props.convertToKg ? (rowData.getWeight() * LBS_TO_KG).toFixed(1) : rowData.getWeight().toFixed(1)
                        )
                      },
                    ]}
                    options={{ pageSize: 20, order: 'desc', orderByIdx: 1 }}
                  />
                </div>
              ) : (
                <Table
                  title={this.props.title}
                  data={FishCatch.groupList(this.props.fishCatch)}
                  columns={FISH_COLUMNS({ convertToKg: this.props.convertToKg, onViewItems: this.onViewItems })}
                  options={{ pageSize: 20, order: 'desc', orderByIdx: 1 }}
                />
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FishCatchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  fishCatch: PropTypes.array,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  convertToKg: PropTypes.bool,
  timezone: PropTypes.string,
  onGoToImage: PropTypes.func
};

export default FishCatchDialog;