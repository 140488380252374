import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, MenuItem, TextField, Typography } from '@mui/material';
import ReactImageGallery from 'react-image-gallery';

// Alerts
import { setError } from '../alerts';

import { getAssociatedFishCatchItemForImage } from '../api';

class ImageGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProjectIndex: null,
      isFullScreen: false,
      displayedImageIndexSelection: '',
      fishCatchItem: null
    };

    this.renderImage = this.renderImage.bind(this);
  }

  componentDidMount() {
    if (this.props.imagePath != null) this.checkForImagePath();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imagePath !== this.props.imagePath && this.props.imagePath != null) {
      this.checkForImagePath();
    }
  }

  checkForImagePath = () => {
    const imagePath = this.props.imagePath;
    for (let i = 0; i < this.props.trip.getProjects().length; i++) {
      const project = this.props.trip.getProjects()[i];
      for (let j = 0; j < project.getImages().length; j++) {
        const image = project.getImages()[j];
        if (image.getFileName() === imagePath) {
          this.setState({ selectedProjectIndex: i, displayedImageIndexSelection: j }, () => this.goToClipNumber());
          return;
        }
      }
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, fishCatchItem: null }, () => {
      this.checkForVideo(0);
    });
  };

  onScreenChange(fullScreenElement) {
    this.setState({
      isFullScreen: !!fullScreenElement
    });
  }

  handleImageLoadingError = (error) => {
    setError(error ? error : 'Error: Unable to load image.');
  }

  renderImage(image) {
    return (
      <div>
        <img className={this.state.isFullScreen ? 'fullscreen-image' : ''} width='90%' height='500' src={image.original} style={{ objectFit: 'contain' }} />

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 20 }}>
          <Typography variant='body1'><b>Image Number:</b> {image.clipNumber}</Typography>
        </div>
      </div>
    );
  }

  goToClipNumber = () => {
    this._imageGallery.slideToIndex(this.state.displayedImageIndexSelection);
  }

  checkForVideo = (index) => {
    let images = this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages();
    if (images == null || images.length === 0) return;
    
    getAssociatedFishCatchItemForImage(this.props.trip.getID(), images[index].getFileName()).then((fishCatchItem) => {
      this.setState({ fishCatchItem });
    }).catch(() => {});
  }

  render() {
    const images = this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages();
    return (
      <div>
        {/* Select Camera */}
        <TextField
          name='selectedProjectIndex'
          label='Camera'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.selectedProjectIndex ?? ''}
          onChange={this.onChange}
          variant='filled'
          select
        >
          {this.props.trip.getProjects()?.map((project, index) => (
            <MenuItem key={index} value={index}>{project.camera?.getName()}</MenuItem>
          ))}
        </TextField>

        {this.state.selectedProjectIndex != null && this.state.selectedProjectIndex < this.props.trip.getProjects()?.length && this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages()?.length > 0 ? (
          <div>
            <ReactImageGallery
              ref={(i) => (this._imageGallery = i)}
              items={images?.map((image, index) => ({
                original: image.getUrl(),
                thumbnail: image.getUrl(),
                clipNumber: (index + 1) + ' of ' + this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages().length,
                renderItem: this.renderImage,
                loading: 'lazy'
              }))}
              infinite={false}
              showPlayButton={false}
              onErrorImageURL={this.handleImageLoadingError}
              onScreenChange={this.onScreenChange.bind(this)}
              lazyLoad
              showThumbnails={false}
              style={{ width: '100%' }}
              onBeforeSlide={() => this.setState({ fishCatchItem: null })}
              onSlide={this.checkForVideo}
            />

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: this.state.fishCatchItem != null ? 'space-between' : 'flex-end' }}>
              
              {this.state.fishCatchItem != null && <Button style={{ width: 150 }} disabled={this.props.onGoToVideo == null} onClick={() => this.props.onGoToVideo({
                fishCatchItem: this.state.fishCatchItem,
                cameraID: this.props.trip.getProjects()[this.state.selectedProjectIndex]?.camera?.getID()
              })}>View Video</Button>}

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TextField
                  label='Jump to Image'
                  value={this.state.displayedImageIndexSelection ?? ''}
                  onChange={(e) => this.setState({ displayedImageIndexSelection: e.target.value })}
                  style={{ width: '150px' }}
                  select
                >
                  {Array.from({ length: this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages().length }, (_, i) => i).map((number) => (
                    <MenuItem key={number} value={number}>{number + 1}</MenuItem>
                  ))}
                </TextField>

                <Button onClick={this.goToClipNumber} style={{ width: '75px', marginLeft: '10px' }}>Go</Button>
              </div>
            </div>
          </div>
        ) : (
          <Typography variant='body1'>
            {this.props.trip.getProjects()[this.state.selectedProjectIndex]?.getImages()?.length === 0 ? 'No images captured for selected camera.' : 'Please select a camera to view images.'}
          </Typography>
        )}
      </div>
    );
  }
}

ImageGallery.propTypes = {
  trip: PropTypes.object.isRequired,
  imagePath: PropTypes.string,
  onGoToVideo: PropTypes.func
};

export default ImageGallery;
